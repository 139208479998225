/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// styles //
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/styles.scss"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          social {
            facebook
            instagram
            linkedin
            twitter
            youtube
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        siteSocial={data.site.siteMetadata?.social || {}}
      />
      <main>{children}</main>
      <Footer
        siteTitle={data.site.siteMetadata?.title || `Title`}
        siteSocial={data.site.siteMetadata?.social || {}}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
