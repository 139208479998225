import * as React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import { Row, Container, Col } from "react-bootstrap"

import fbicon from "../images/svg-icons/fb-white.svg"
import igicon from "../images/svg-icons/ig-white.svg"
import inicon from "../images/svg-icons/in-white.svg"

const Footer = ({ siteTitle, siteSocial }) => (
  <footer className="footer_area">
    <div className="footer_outer">
      <Container>
        <div className="footer_inner">
          <Row className="f_widget_parent">
            <Col sm={12} md={4} lg={3} xl={2} className="f_widget offset-xl-1">
              <address>
                <p>
                  G7 Ground Floor <br />
                  Cears Plaza <br />
                  136 Residency Road <br />
                  Opp. Bangalore Club <br />
                  Bangalore 560 025 <br />
                  KA India <br />
                  <br />
                  +91 80 41499315 <br />
                  +91 9945747423 <br />
                  ap@wdaspaces.com
                </p>
              </address>
            </Col>
            {/* <Col xs={12} md={2} className="f_widget">
              <address>
                <p>
                  E2002, Raheja Vistas, <br />
                  Raheja Vihar, <br />
                  Chandivali, <br />
                  Mumbai 400 072. <br />
                  MH India.
                  <br />
                  <br />
                  +91 9743314444
                </p>
              </address>
            </Col> */}
            <Col sm={12} md={8} lg={3} xl={2} className="f_widget main-links">
              <ul className="footer-links list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/awards-&#38;-media">Awards &amp; Media</Link>
                </li>
                <li>
                  <Link to="/who-we-are">Who we are</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </Col>

            <Col sm={12} md={4} lg={2} className="f_widget sub-links">
              <h4>F&amp;B</h4>
              <ul className="footer-links list">
                <li>
                  <Link to="/projects/bars-&amp;-lounges">
                    Bars &amp; Lounges
                  </Link>
                </li>
                <li>
                  <Link to="/projects/restaurants-&amp;-eateries">
                    Restaurants &amp; Eateries
                  </Link>
                </li>
                <li>
                  <Link to="/projects/microbreweries-&amp;-taprooms">
                    Microbreweries &amp; Taprooms
                  </Link>
                </li>
              </ul>
            </Col>

            <Col sm={12} md={4} lg={2} className="f_widget sub-links">
              <h4>Leisure</h4>
              <ul className="footer-links list">
                <li>
                  <Link to="/projects/hospitality">Hospitality</Link>
                </li>
                <li>
                  <Link to="/projects/entertainment/">Entertainment</Link>
                </li>
              </ul>
            </Col>
            <Col sm={12} md={4} lg={2} className="f_widget sub-links">
              <h4>Luxury</h4>
              <ul className="footer-links list">
                <li>
                  <Link to="/projects/workspaces">Workspaces</Link>
                </li>
                <li>
                  <Link to="/projects/retail">Retail</Link>
                </li>
              </ul>
            </Col>
          </Row>
          <div className="social_widget">
            <ul className="list">
              <li>
                <a
                  href={siteSocial.facebook}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={fbicon} alt="facebook" />
                </a>
              </li>
              <li>
                <a
                  href={siteSocial.instagram}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={igicon} alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  href={siteSocial.linkedin}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={inicon} alt="LinkedIn" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
    <div className="copyright">
      &copy; {new Date().getFullYear()} {siteTitle} <br />
      All trademarks are the property of their respective owners.
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteSocial: PropTypes.object,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteSocial: {},
}

export default Footer
