import * as React from "react"
import { useState } from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import { Fade } from "react-bootstrap"

import Logo from "../images/wda_logo.svg"
import fbicon from "../images/svg-icons/fb-grey.svg"
import igicon from "../images/svg-icons/ig-grey.svg"
import inicon from "../images/svg-icons/in-grey.svg"

const Header = ({ siteTitle, siteSocial }) => {
  const [open, setOpen] = useState(false)
  return (
    <header className="header_area">
      <div className="header_outer">
        <h1>
          <Link title={siteTitle} to="/">
            <img src={Logo} alt={siteTitle} />
          </Link>
        </h1>
        <button
          className={`menu-button ${open ? "show" : ""}`}
          onClick={() => setOpen(!open)}
          aria-controls="Menu"
          aria-expanded={open}
        >
          <span className="patt-1"></span>
          <span className="patt-2"></span>
          <span className="patt-3"></span>
          Menu
        </button>
        <Fade in={open}>
          <div className="main_menu">
            <nav>
              <ul className="list">
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/awards-&-media">Awards &amp; Media</Link>
                </li>
                <li>
                  <Link to="/who-we-are">Who we are</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
            <div className="header_social">
              <ul className="list">
                <li>
                  <a
                    href={siteSocial.facebook}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={fbicon} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="//www.instagram.com/wdaspaces/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={igicon} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="//www.linkedin.com/company/wdaspaces"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={inicon} alt="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Fade>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSocial: PropTypes.object,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSocial: {},
}

export default Header
